<template>
    <header class="header" :class="['header-' + this.$route.name, {'fixed': menu_fixed, 'transparent': menu_transparent, 'active': menu_open}]">
        
        <nav>
            <router-link to="/" class="logo" v-on:click.native="menu_open = false">
                <img :src="logo" id="logo" class="logo-fixed" alt="Sleekplan: The all-in-one user feedback tool" width="40" height="40">
                <img :src="logo_scroll" id="logo-scroll" class="logo-scroll" alt="Sleekplan: The all-in-one user feedback tool" width="40" height="40">
                <div>Sleekplan</div>
            </router-link>
            <div class="menu">
                <div class="item sub" v-on:click="menu_clicked()" :class="{hide: sub_menu === true}">
                    <router-link to="/features/">
                        Product
                    </router-link>
                    <div class="menu-section" v-if="sub_menu !== true">
                        <div class="menu-section-inner">
                            <div class="menu-section-main">
                                <div class="col">
                                    <div class="headline">Modules</div>
                                    <div class="list cyan">
                                        
                                        <router-link to="/features/feedback" class="item">
                                            <div class="icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Chatbubble</title><path d="M76.83 480a25.69 25.69 0 01-25.57-25.74 29.13 29.13 0 011.2-7.63L70.88 380c.77-2.46-.1-4.94-1.23-6.9l-.22-.4c-.08-.13-.46-.66-.73-1.05s-.58-.81-.86-1.22l-.19-.27A215.66 215.66 0 0132 251.37c-.18-57.59 22.35-112 63.46-153.28C138 55.47 194.9 32 255.82 32A227.4 227.4 0 01398 81.84c39.45 31.75 66.87 76 77.21 124.68a213.5 213.5 0 014.78 45c0 58.93-22.64 114.28-63.76 155.87-41.48 42-97.18 65.06-156.83 65.06-21 0-47.87-5.36-60.77-9-15.52-4.34-30.23-10-31.85-10.6a15.12 15.12 0 00-5.37-1 14.75 14.75 0 00-5.8 1.15l-.85.33-67.48 24.38A29.44 29.44 0 0176.83 480zm-2-31.8zM87.48 380z"/></svg>
                                                </div>
                                            </div>
                                            <div class="text">
                                                <span>Feedback Board</span>
                                                <span>Collect suggestions</span>
                                            </div>
                                        </router-link>
                                        <router-link to="/features/roadmap" class="item">
                                            <div class="icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Map</title><path d="M48.17 113.34A32 32 0 0032 141.24V438a32 32 0 0047 28.37c.43-.23.85-.47 1.26-.74l84.14-55.05a8 8 0 003.63-6.72V46.45a8 8 0 00-12.51-6.63zM212.36 39.31A8 8 0 00200 46v357.56a8 8 0 003.63 6.72l96 62.42A8 8 0 00312 466V108.67a8 8 0 00-3.64-6.73zM464.53 46.47a31.64 31.64 0 00-31.5-.88 12.07 12.07 0 00-1.25.74l-84.15 55a8 8 0 00-3.63 6.72v357.46a8 8 0 0012.52 6.63l107.07-73.46a32 32 0 0016.41-28v-296a32.76 32.76 0 00-15.47-28.21z"/></svg>
                                                </div>
                                            </div>
                                            <div class="text">
                                                <span>Public Roadmap</span>
                                                <span>Share your progress</span>
                                            </div>
                                        </router-link>
                                        <router-link to="/features/changelog" class="item">
                                            <div class="icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>List</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M160 144h288M160 256h288M160 368h288"/><circle cx="80" cy="144" r="16" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><circle cx="80" cy="256" r="16" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><circle cx="80" cy="368" r="16" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
                                                </div>
                                            </div>
                                            <div class="text">
                                                <span>Changelog</span>
                                                <span>Share updates</span>
                                            </div>
                                        </router-link>
                                        <router-link to="/features/satisfaction" class="item">
                                            <div class="icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Heart</title><path d="M256 448a32 32 0 01-18-5.57c-78.59-53.35-112.62-89.93-131.39-112.8-40-48.75-59.15-98.8-58.61-153C48.63 114.52 98.46 64 159.08 64c44.08 0 74.61 24.83 92.39 45.51a6 6 0 009.06 0C278.31 88.81 308.84 64 352.92 64c60.62 0 110.45 50.52 111.08 112.64.54 54.21-18.63 104.26-58.61 153-18.77 22.87-52.8 59.45-131.39 112.8a32 32 0 01-18 5.56z"/></svg>
                                                </div>
                                            </div>
                                            <div class="text">
                                                <span>Satisfaction (CSAT)</span>
                                                <span>Measure satisfaction</span>
                                            </div>
                                        </router-link>
                                        <router-link to="/features/net-promoter-score" class="item">
                                            <div class="icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Happy</title><path d="M414.39 97.61A224 224 0 1097.61 414.39 224 224 0 10414.39 97.61zM184 208a24 24 0 11-24 24 23.94 23.94 0 0124-24zm167.67 106.17c-12 40.3-50.2 69.83-95.62 69.83s-83.62-29.53-95.72-69.83a8 8 0 017.83-10.17h175.69a8 8 0 017.82 10.17zM328 256a24 24 0 1124-24 23.94 23.94 0 01-24 24z"/></svg>
                                                </div>
                                            </div>
                                            <div class="text">
                                                <span>Net Promoter Score®</span>
                                                <span>Track customer happiness</span>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="headline">More</div>
                                    <div class="list dark">
                                        <router-link to="/features/" class="item" rel="nofollow">
                                            <div class="icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Rocket</title><path d="M328.85 156.79a26.69 26.69 0 1018.88 7.81 26.6 26.6 0 00-18.88-7.81z"/><path d="M477.44 50.06a.29.29 0 010-.09 20.4 20.4 0 00-15.13-15.3c-29.8-7.27-76.68.48-128.63 21.28-52.36 21-101.42 52-134.58 85.22A320.7 320.7 0 00169.55 175c-22.33-1-42 2.18-58.57 9.41-57.74 25.41-74.23 90.44-78.62 117.14a25 25 0 0027.19 29h.13l64.32-7.02c.08.82.17 1.57.24 2.26a34.36 34.36 0 009.9 20.72l31.39 31.41a34.27 34.27 0 0020.71 9.91l2.15.23-7 64.24v.13A25 25 0 00206 480a25.25 25.25 0 004.15-.34C237 475.34 302 459.05 327.34 401c7.17-16.46 10.34-36.05 9.45-58.34a314.78 314.78 0 0033.95-29.55c33.43-33.26 64.53-81.92 85.31-133.52 20.69-51.36 28.48-98.59 21.39-129.53zM370.38 224.94a58.77 58.77 0 110-83.07 58.3 58.3 0 010 83.07z"/><path d="M161.93 386.44a16 16 0 00-11 2.67c-6.39 4.37-12.81 8.69-19.29 12.9-13.11 8.52-28.79-6.44-21-20l12.15-21a16 16 0 00-15.16-24.91A61.25 61.25 0 0072 353.56c-3.66 3.67-14.79 14.81-20.78 57.26A357.94 357.94 0 0048 447.59 16 16 0 0064 464h.4a359.87 359.87 0 0036.8-3.2c42.47-6 53.61-17.14 57.27-20.8a60.49 60.49 0 0017.39-35.74 16 16 0 00-13.93-17.82z"/></svg>
                                                </div>
                                            </div>
                                            <div class="text">
                                                <span>All Features</span>
                                                <span>Features overview</span>
                                            </div>
                                        </router-link>
                                        <router-link to="/use-case/" class="item">
                                            <div class="icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Help Circle</title><path d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64zm-6 304a20 20 0 1120-20 20 20 0 01-20 20zm33.44-102C267.23 276.88 265 286.85 265 296a14 14 0 01-28 0c0-21.91 10.08-39.33 30.82-53.26C287.1 229.8 298 221.6 298 203.57c0-12.26-7-21.57-21.49-28.46-3.41-1.62-11-3.2-20.34-3.09-11.72.15-20.82 2.95-27.83 8.59C215.12 191.25 214 202.83 214 203a14 14 0 11-28-1.35c.11-2.43 1.8-24.32 24.77-42.8 11.91-9.58 27.06-14.56 45-14.78 12.7-.15 24.63 2 32.72 5.82C312.7 161.34 326 180.43 326 203.57c0 33.83-22.61 49.02-42.56 62.43z"/></svg>
                                                </div>
                                            </div>
                                            <div class="text">
                                                <span>Use Cases</span>
                                                <span>Covered by Sleekplan</span>
                                            </div>
                                        </router-link>
                                        <router-link to="/integration/" class="item">
                                            <div class="icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Cube</title><path d="M440.9 136.3a4 4 0 000-6.91L288.16 40.65a64.14 64.14 0 00-64.33 0L71.12 129.39a4 4 0 000 6.91L254 243.88a4 4 0 004.06 0zM54 163.51a4 4 0 00-6 3.49v173.89a48 48 0 0023.84 41.39L234 479.51a4 4 0 006-3.46V274.3a4 4 0 00-2-3.46zM272 275v201a4 4 0 006 3.46l162.15-97.23A48 48 0 00464 340.89V167a4 4 0 00-6-3.45l-184 108a4 4 0 00-2 3.45z"/></svg>
                                                </div>
                                            </div>
                                            <div class="text">
                                                <span>Integrations</span>
                                                <span>Integrated with your favorite tools</span>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="menu-section-footer">
                                <a href="https://help.sleekplan.com" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><title>ionicons-v5-e</title><path d="M248,64C146.39,64,64,146.39,64,248s82.39,184,184,184,184-82.39,184-184S349.61,64,248,64Z" style="fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:32px"/><polyline points="220 220 252 220 252 336" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="208" y1="340" x2="296" y2="340" style="fill:none;stroke:#000;stroke-linecap:round;stroke-miterlimit:10;stroke-width:32px"/><path d="M248,130a26,26,0,1,0,26,26A26,26,0,0,0,248,130Z"/></svg>
                                    <span>Knowledge Base</span>
                                </a>
                                <a href="https://sleekplan.com/docs/" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><title>ionicons-v5-m</title><polyline points="160 368 32 256 160 144" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><polyline points="352 368 480 256 352 144" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/></svg>
                                    <span>Developer Docs</span>
                                </a>
                                <a href="https://feedback.sleekplan.com/roadmap" target="_blank">
                                    <svg id="rocket-outline" class="ionicon" viewBox="0 0 512 512" width="100%" height="100%"><title>Rocket</title><path d="M461.81 53.81a4.4 4.4 0 00-3.3-3.39c-54.38-13.3-180 34.09-248.13 102.17a294.9 294.9 0 00-33.09 39.08c-21-1.9-42-.3-59.88 7.5-50.49 22.2-65.18 80.18-69.28 105.07a9 9 0 009.8 10.4l81.07-8.9a180.29 180.29 0 001.1 18.3 18.15 18.15 0 005.3 11.09l31.39 31.39a18.15 18.15 0 0011.1 5.3 179.91 179.91 0 0018.19 1.1l-8.89 81a9 9 0 0010.39 9.79c24.9-4 83-18.69 105.07-69.17 7.8-17.9 9.4-38.79 7.6-59.69a293.91 293.91 0 0039.19-33.09c68.38-68 115.47-190.86 102.37-247.95zM298.66 213.67a42.7 42.7 0 1160.38 0 42.65 42.65 0 01-60.38 0z" stroke-linecap="round" stroke-linejoin="round" class="ionicon-fill-none ionicon-stroke-width"></path><path d="M109.64 352a45.06 45.06 0 00-26.35 12.84C65.67 382.52 64 448 64 448s65.52-1.67 83.15-19.31A44.73 44.73 0 00160 402.32" stroke-linecap="round" stroke-linejoin="round" class="ionicon-fill-none ionicon-stroke-width"></path></svg>
                                    <span>Roadmap</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <router-link to="/pricing/">Pricing</router-link>
                </div>
                <div class="item">
                    <router-link to="/demo/" rel="nofollow">Demo</router-link>
                </div>
                <div class="item">
                    <router-link to="/customers/" rel="nofollow">Customers</router-link>
                </div>
                <div class="item">
                    <router-link to="/about/" rel="nofollow">About</router-link>
                </div>
                <div class="item ir">
                    <a href="https://app.sleekplan.com/login/" rel="nofollow">Sign in</a>
                </div>
                <div class="item">
                    <a href="https://app.sleekplan.com/sign-up/" rel="nofollow" class="btn uppercase">
                        <span>Get started</span>
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M184 112l144 144-144 144'/></svg>
                    </a>
                </div>
            </div>
            <div class="mobile-menu">
                <div class="hamburger hamburger--spin" :class="{'is-active': menu_open}" type="button" v-on:click="menu_open = !menu_open">
                    <div class="hamburger-box">
                        <div class="hamburger-inner" ></div>
                    </div>
                </div>
                <div class="mobile-menu-outer">
                    <div class="mobile-menu-inner">
                        <div class="item">
                            <router-link to="/features/" v-on:click.native="menu_open = false">Features</router-link>
                        </div>
                        <div class="item">
                            <router-link to="/pricing/" v-on:click.native="menu_open = false">Pricing</router-link>
                        </div>
                        <div class="item">
                            <router-link to="/demo/" rel="nofollow" v-on:click.native="menu_open = false">Demo</router-link>
                        </div>
                        <div class="item">
                            <router-link to="/about/" rel="nofollow" v-on:click.native="menu_open = false">About</router-link>
                        </div>
                        <div class="item ir">
                            <a href="https://app.sleekplan.com/login/" rel="nofollow">Sign in</a>
                        </div>
                        <div class="item">
                            <a href="https://app.sleekplan.com/sign-up/" rel="nofollow" class="btn">Get started</a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>

    </header>
</template>

<script>
export default {


    /** ----------------------------------------------------
     * View => Base data
     ----------------------------------------------------- */
    data() {
        
        // data
		return {
            scrolling_data: {},
            menu_fixed: false,
            menu_open: false,
            menu_transparent: false,
            sub_menu: {},
            logo: require('@/assets/media/logo/logo.svg'),
            logo_scroll: require('@/assets/media/logo/logo.svg'),
        };

    },


    /** ----------------------------------------------------
     * View => Methods
     ----------------------------------------------------- */
    methods: {

        /**
         *  scrolling
         */
        scrolling() {

            // set top distance to 0
            this.scrolling_data.top = 0;

            // (re)set default
            this.menu_fixed = false;

            // on load scroll
            document.addEventListener('scroll', this.scrolling_scroll);

        },

        /**
         *  On scrolling
         */
        scrolling_scroll() {

            // get top offset for main
            this.scrolling_data.top = document.querySelector('.page').getBoundingClientRect().top;
            
            // if top offset is more than 80px
            if( this.scrolling_data.top < -400 ) {
                if ( this.menu_fixed == false ) {
                    // load fixed menu bar
                    this.menu_fixed = true;
                }
            } else {
                // default menu bar
                this.menu_fixed = false;
            }

        },

        /**
         *  Stop scrolling 
         */
        scrolling_stop() {

            // remove scrolling
            this.menu_fixed = false;

            // stop event listener
            document.removeEventListener('scroll', this.scrolling_scroll);

        },

        /**
         *  Submenu 
         */
        menu_clicked() {

            // check submenu
            this.sub_menu = true; 
            
            // wait shortly
            setTimeout(() => {

                // set sub menu
                this.sub_menu = false; 

            }, 500);

        }

	},


    /** ----------------------------------------------------
     * View => On Mount
     ----------------------------------------------------- */
    mounted() {

        // scrolling effect
        this.scrolling();
        
    },


    /** ----------------------------------------------------
     * View => On Destroyed
     ----------------------------------------------------- */
    destroyed() {

        // scrolling effect stop
        this.scrolling_stop();

    }


}
</script> 